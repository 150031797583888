import type {DragEndEvent} from "@dnd-kit/core"
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core"
import {restrictToVerticalAxis} from "@dnd-kit/modifiers"
import {SortableContext, arrayMove, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"
import {Outlet, useLocation} from "@remix-run/react"
import {clsx} from "clsx"
import {AnimatePresence, motion} from "motion/react"
import React from "react"
import {thumbHashToDataURL} from "thumbhash"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as UserSettingsActionCreators from "~/actions/UserSettingsActionCreators"
import {FluxerSymbol} from "~/components/icons/FluxerSymbol"
import {AddSpaceModal} from "~/components/modals/AddSpaceModal"
import {UserSettingsModal} from "~/components/modals/UserSettingsModal"
import {MentionBadgeAnimated} from "~/components/uikit/MentionBadge"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import Dispatcher from "~/flux/Dispatcher"
import {useHover} from "~/hooks/useHover"
import {i18n} from "~/i18n"
import {AddIcon} from "~/icons/AddIcon"
import {ExclamationIcon} from "~/icons/ExclamationIcon"
import type {SpaceRecord} from "~/records/SpaceRecord"
import MobileLayoutStore from "~/stores/MobileLayoutStore"
import ReadStateStore from "~/stores/ReadStateStore"
import SelectedChannelStore from "~/stores/SelectedChannelStore"
import SpaceAvailabilityStore from "~/stores/SpaceAvailabilityStore"
import SpaceListStore from "~/stores/SpaceListStore"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as ImageCacheUtils from "~/utils/ImageCacheUtils"
import * as RouterUtils from "~/utils/RouterUtils"
import * as StringUtils from "~/utils/StringUtils"

type SpaceListItemProps = {
  space: SpaceRecord
  isSortingList?: boolean
}

const SpaceListItem = ({space, isSortingList = false}: SpaceListItemProps) => {
  const iconUrl = AvatarUtils.getSpaceIconURL(space, false)
  const hoverIconUrl = AvatarUtils.getSpaceIconURL(space, true)

  const [hoverRef, isHovering] = useHover()
  const [isStaticLoaded, setIsStaticLoaded] = React.useState(ImageCacheUtils.hasImage(iconUrl))
  const [isAnimatedLoaded, setIsAnimatedLoaded] = React.useState(ImageCacheUtils.hasImage(hoverIconUrl))
  const [shouldPlayAnimated, setShouldPlayAnimated] = React.useState(false)

  const location = useLocation()
  const hasUnreadMessages = ReadStateStore.useSpaceUnreadMessages(space.id)
  const parsedAvatar = space.icon ? AvatarUtils.parseAvatar(space.icon) : null
  const isSelected = location.pathname.startsWith(`/channels/${space.id}`)
  const mentionCount = ReadStateStore.useSpaceMentionCount(space.id)
  const selectedChannel = SelectedChannelStore.useSelectedChannel(space.id)
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: space.id})

  React.useEffect(() => {
    ImageCacheUtils.loadImage(iconUrl, () => setIsStaticLoaded(true))
    if (isHovering) {
      ImageCacheUtils.loadImage(hoverIconUrl, () => setIsAnimatedLoaded(true))
    }
  }, [iconUrl, hoverIconUrl, isHovering])

  React.useEffect(() => {
    setShouldPlayAnimated(isHovering && isAnimatedLoaded)
  }, [isHovering, isAnimatedLoaded])

  const handleSelect = React.useCallback(() => {
    RouterUtils.transitionTo(`/channels/${space.id}/${selectedChannel}`)
    Dispatcher.dispatch({type: "SPACE_SELECT", spaceId: space.id})
  }, [selectedChannel, space.id])

  const animationSettings = {
    opacity: 1,
    scale: 1,
    height: isSelected ? 40 : isHovering ? 20 : 8,
  }

  const motionSettings = {
    animate: animationSettings,
    exit: {opacity: 0, scale: 0},
    initial: animationSettings,
    transition: {type: "spring", stiffness: 500, damping: 20},
  }

  return (
    <Tooltip
      position="right"
      maxWidth="xl"
      text={() =>
        !isSortingList && (
          <div className="flex flex-col">
            <span className="font-medium text-base text-text-primary">{space.name}</span>
            {space.unavailable && (
              <span className="text-sm text-text-primary-muted">{i18n.Messages.SPACE_OUTAGE_DESCRIPTION}</span>
            )}
          </div>
        )
      }
    >
      <div
        className="relative mb-2 flex w-full justify-center"
        aria-label={`${space.name}${isSelected ? " (selected)" : ""}`}
        aria-pressed={isSelected}
        onClick={handleSelect}
        onKeyDown={(event) => event.key === "Enter" && handleSelect()}
        ref={hoverRef}
        role="button"
        tabIndex={0}
      >
        <AnimatePresence>
          {!isSortingList && (hasUnreadMessages || isSelected || isHovering) && (
            <div className="contain-[layout size] pointer-events-none absolute top-0 left-0 flex h-12 w-2 items-center justify-start">
              <motion.span {...motionSettings} className="-ml-1 absolute w-2 rounded-r-full bg-text-primary" />
            </div>
          )}
        </AnimatePresence>

        <div className="relative">
          <motion.div
            {...attributes}
            {...listeners}
            ref={setNodeRef}
            className={clsx(
              "active:translate-z-0 flex h-12 w-12 flex-shrink-0 transform-gpu cursor-pointer items-center justify-center rounded-full bg-center bg-cover font-medium text-text-tertiary text-xl active:translate-y-px",
              !space.icon && "bg-background-navbar-surface",
            )}
            animate={{borderRadius: isSelected || isHovering ? "30%" : "50%"}}
            initial={{borderRadius: isSelected || isHovering ? "30%" : "50%"}}
            transition={{type: "spring", stiffness: 500, damping: 30}}
            whileHover={{borderRadius: "30%"}}
            style={{
              backgroundImage: isStaticLoaded
                ? `url(${shouldPlayAnimated && isAnimatedLoaded ? hoverIconUrl : iconUrl})`
                : parsedAvatar
                  ? `url(${thumbHashToDataURL(Uint8Array.from(atob(parsedAvatar.placeholder), (c) => c.charCodeAt(0)))})`
                  : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: isDragging ? "grabbing" : undefined,
              opacity: isDragging ? 0.5 : 1,
              transform: CSS.Transform.toString(transform),
              transition,
            }}
          >
            {!space.icon && StringUtils.getInitialsFromName(space.name)}
          </motion.div>

          {!(isSortingList || space.unavailable) && mentionCount > 0 && (
            <div className="pointer-events-none absolute right-0 bottom-0 rounded-full shadow-[0_0_0_3px_var(--background-secondary)]">
              <MentionBadgeAnimated mentionCount={mentionCount} />
            </div>
          )}

          {space.unavailable && !isSortingList && (
            <div className="pointer-events-none absolute top-0 right-0">
              <div className="flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full bg-white text-status-danger shadow-[0_0_0_3px_var(--background-secondary)]">
                <ExclamationIcon className="h-4 w-4" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

const FluxerButton = () => {
  const [hoverRef, isHovering] = useHover()
  const location = useLocation()
  const isSelected = location.pathname === "/"

  const handleSelect = React.useCallback(() => {
    RouterUtils.transitionTo("/")
  }, [])

  const animationSettings = {
    opacity: 1,
    scale: 1,
    height: isSelected ? 40 : isHovering ? 20 : 8,
  }

  const motionSettings = {
    animate: animationSettings,
    exit: {opacity: 0, scale: 0},
    initial: animationSettings,
    transition: {type: "spring", stiffness: 500, damping: 20},
  }

  return (
    <Tooltip
      position="right"
      text={() => (
        <div className="flex items-center gap-1 text-text-primary">
          <span className="font-medium text-base">{i18n.Messages.DIRECT_MESSAGES}</span>
        </div>
      )}
    >
      <div
        className="relative mb-2 flex w-full justify-center"
        aria-label={i18n.Messages.DIRECT_MESSAGES}
        aria-pressed={isSelected}
        onClick={handleSelect}
        onKeyDown={(event) => event.key === "Enter" && handleSelect()}
        ref={hoverRef}
        role="button"
        tabIndex={0}
      >
        <AnimatePresence>
          {(isSelected || isHovering) && (
            <div className="contain-[layout size] pointer-events-none absolute top-0 left-0 flex h-12 w-2 items-center justify-start">
              <motion.span {...motionSettings} className="-ml-1 absolute w-2 rounded-r-full bg-text-primary" />
            </div>
          )}
        </AnimatePresence>
        <motion.div
          className="active:translate-z-0 flex h-12 w-12 flex-shrink-0 transform-gpu cursor-pointer items-center justify-center rounded-full bg-brand-primary text-brand-primary-fill transition-colors duration-150 ease-out active:translate-y-px"
          onClick={handleSelect}
          animate={{borderRadius: isHovering || isSelected ? "30%" : "50%"}}
          initial={{borderRadius: isHovering || isSelected ? "30%" : "50%"}}
          transition={{type: "spring", stiffness: 500, damping: 30}}
          whileHover={{borderRadius: "30%"}}
        >
          <FluxerSymbol className="h-12 w-12" />
        </motion.div>
      </div>
    </Tooltip>
  )
}

const AddSpaceButton = () => {
  const [hoverRef, isHovering] = useHover()
  return (
    <div className="relative mb-2 flex w-full justify-center">
      <Tooltip
        position="right"
        text={() => (
          <div className="flex items-center gap-1 text-text-primary">
            <span className="font-medium text-base">{i18n.Messages.ADD_A_SPACE}</span>
          </div>
        )}
      >
        <div
          role="button"
          tabIndex={0}
          aria-label={i18n.Messages.ADD_A_SPACE}
          onClick={() => ModalActionCreators.push(() => <AddSpaceModal />)}
          onKeyDown={(event) => event.key === "Enter" && ModalActionCreators.push(() => <AddSpaceModal />)}
          className="relative flex w-full justify-center"
          ref={hoverRef}
        >
          <motion.div
            className={clsx(
              "active:translate-z-0 flex h-12 w-12 flex-shrink-0 transform-gpu cursor-pointer items-center justify-center rounded-full bg-background-navbar-surface text-green-500 transition-colors duration-150 ease-out active:translate-y-px",
              isHovering && "bg-green-500 text-white",
            )}
            animate={{borderRadius: isHovering ? "30%" : "50%"}}
            initial={{borderRadius: isHovering ? "30%" : "50%"}}
            transition={{type: "spring", stiffness: 500, damping: 30}}
            whileHover={{borderRadius: "30%"}}
          >
            <AddIcon className="h-8 w-8" />
          </motion.div>
        </div>
      </Tooltip>
    </div>
  )
}

const SpaceList = () => {
  const [isDragging, setIsDragging] = React.useState(false)
  const {spaces} = SpaceListStore.useStore()
  const mobileLayout = MobileLayoutStore.useStore()
  const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}))
  const unavailableSpaces = SpaceAvailabilityStore.useUnavailableSpaces()

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event
      if (over && active.id !== over.id) {
        const oldIndex = spaces.findIndex((space) => space.id === active.id)
        const newIndex = spaces.findIndex((space) => space.id === over.id)
        const newArray = arrayMove(spaces, oldIndex, newIndex)
        UserSettingsActionCreators.update({
          space_positions: newArray.map((space) => space.id),
        })
      }
      setIsDragging(false)
    },
    [spaces],
  )

  const renderSpace = React.useCallback(
    (space: SpaceRecord) => <SpaceListItem key={space.id} isSortingList={isDragging} space={space} />,
    [isDragging],
  )

  return (
    <div className="no-scrollbar h-full min-h-0 w-full min-w-0 overflow-y-auto bg-background-secondary pt-2">
      <div className="flex h-full min-h-0 w-full min-w-0 flex-col items-center">
        <FluxerButton />

        {unavailableSpaces.size > 0 && (
          <Tooltip
            position="right"
            type={"error"}
            maxWidth="xl"
            text={() => (
              <div className="flex items-center gap-1 text-text-primary">
                <span className="font-medium text-base">
                  {i18n.format(i18n.Messages.SPACE_OUTAGE_TOOLTIP, {count: unavailableSpaces.size})}
                </span>
              </div>
            )}
          >
            <div className="relative mb-2 flex w-full justify-center">
              <div className="flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-status-danger bg-transparent text-text-primary transition-colors duration-150 ease-out hover:bg-status-danger hover:text-white active:translate-y-px">
                <ExclamationIcon className="h-8 w-8" />
              </div>
            </div>
          </Tooltip>
        )}

        <div className="mb-2 h-0.5 w-8 flex-shrink-0 rounded-[1px] bg-background-navbar-surface" />

        {spaces.length > 0 && (
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            onDragStart={() => setIsDragging(true)}
            sensors={sensors}
          >
            <SortableContext
              disabled={spaces.length === 1 || mobileLayout.enabled}
              items={spaces.map((space) => space.id)}
              strategy={verticalListSortingStrategy}
            >
              {spaces.map((space) => renderSpace(space))}
            </SortableContext>
          </DndContext>
        )}

        <AddSpaceButton />
      </div>
    </div>
  )
}

export const SpacesLayout = () => {
  const mobileLayout = MobileLayoutStore.useStore()

  const handleOpenUserSettingsModal = React.useCallback(() => {
    ModalActionCreators.push(UserSettingsModal)
  }, [])

  React.useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.metaKey && (event.key === "," || event.key === "p")) {
        handleOpenUserSettingsModal()
        event.preventDefault()
      }
    }

    if (!mobileLayout.enabled) {
      document.addEventListener("keydown", handleKeydown)
    }

    return () => {
      if (!mobileLayout.enabled) {
        document.removeEventListener("keydown", handleKeydown)
      }
    }
  }, [handleOpenUserSettingsModal, mobileLayout.enabled])

  return (
    <div
      className={clsx(
        "grid h-full min-h-0 w-full min-w-0 bg-background-secondary text-text-primary md:bg-background-primary",
        (!mobileLayout.enabled || mobileLayout.navExpanded) && "grid-cols-[4.5rem,1fr]",
      )}
    >
      {(!mobileLayout.enabled || mobileLayout.navExpanded) && <SpaceList />}
      <div className="min-h-0 min-w-0 bg-background-secondary">
        <div className="h-full min-h-0 w-full min-w-0 bg-background-chat-primary">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
